export default {
  name: "StandardSuccess",
  props: {
    text: {
      type: String,
      default: ""
    },
    redirectText: {
      type: String,
      default: ""
    },
    hasLink: {
      type: Boolean,
      default: false
    },
    redirectPath: {
      type: String,
      default: ""
    }
  }
};
