import StandardSuccess from "@/components/StandardSuccess/StandardSuccess.vue";
import { validationMixin } from "@zoox-framework/smck-service";
import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import PaymentService from "@/services/PaymentService";

export default {
  name: "SmsMessage",
  mixins: [validationMixin],
  components: {
    StandardSuccess: StandardSuccess
  },
  data() {
    return {
      personService: new PersonService(),
      paymentService: new PaymentService(),
      companyService: new CompanyService(),
      codeConfirmed: false,
      confirmationCode: "",
      documentTypeId: "",
      companyId: "",
      documentNumber: ""
    };
  },
  created() {
    const { documentTypeId, documentNumber } = this.$route.params;
    const company = this.companyService.getCurrentCompany();
    this.companyId = company.id;
    this.documentTypeId = documentTypeId;
    this.documentNumber = documentNumber;
  },
  methods: {
    async validateCode() {
      if (this.$refs.codeForm.validate()) {
        const data = { confirmationCode: this.confirmationCode };
        const resp = await this.personService
          .confirmRegistration(data)
          .catch(() => {
            this.$toaster.warning(this.$t("REGISTRATION.INVALIDCODE"));
          });
        if (resp && resp.id) {
          const token = await this.paymentService.getChargeToken();
          if (!token) {
            this.$toaster.warning(this.$t("REGISTRATION.NO_TOKEN"));
          } else {
            this.codeConfirmed = true;
            await this.$router.push(`auth?chargeToken=${token}`);
          }
        }
      }
    },
    async resendCode() {
      const data = {
        documentTypeId: this.documentTypeId,
        documentNumber: this.documentNumber,
        companyId: this.companyId
      };
      this.personService.resendRegistrationCode(data).then(() => {
        this.$toaster.success(this.$t("PAYMENT_SOLICITATION.PIN_GENERATED"));
      });
    }
  }
};
